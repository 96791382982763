import { MessageDiv, StyledArticle } from '../../styles';
import React, { useEffect, useState } from 'react';
import { StyledH2, StyledImagesContainer } from '../../styles/common';

import { ImageZoom } from '../../styles/tabor';
import { Layout } from '../../components/Layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SEO } from '../../components/SEO';

export const Head = () => (
	<SEO 
	title='120 lat komunikacji miejskiej'
	description='120 lat komunikacji miejskiej.'
	/>);

const isBrowser = () => typeof window !== 'undefined';

const Lat120 = () => {
	const [zoom, setZoom] = useState(false);
	const [image, setImage] = useState(null);
	const [isMobile, setIsMobile] = useState(false);
	const handleOnResize = () => {
		setIsMobile(isBrowser() ? window.innerWidth < 800 : false);
	};
	useEffect(() => {
		if (isBrowser()) {
			window.addEventListener('scroll', handleScrollEvent);
		}
		return () => {
			if (isBrowser()) {
				window.removeEventListener('scroll', handleScrollEvent);
			}
		};
	});

	const handleScrollEvent = () => {
		setZoom(false);
	};

	useEffect(() => {
		if (isBrowser()) {
			window.addEventListener('resize', handleOnResize);
		}
		return () => {
			if (isBrowser()) {
				window.removeEventListener('resize', handleOnResize);
			}
		};
	}, []);
	return (
		<>
			<Layout>
				<StyledArticle>
					<StyledH2 as="h1" color="info">
						120 lat komunikacji miejskiej
					</StyledH2>
					<MessageDiv>
						<p>
							W dniach 30-31 maja Bydgoszcz obchodziła 120-lecie komunikacji
							miejskiej. Organizatorzy jubileuszu postarali się o parady
							zabytkowego taboru, wystawy i szereg innych atrakcji.
						</p>
						<div>
							Świętowanie zaczęło się już w piątek 30 maja 2008 roku. W sali
							sesyjnej ratusza przy ul. Jezuickiej 1 została zorganizowana sesja
							popularnonaukowa pn. &apos;120 lat Komunikacji Miejskiej w
							Bydgoszczy&apos;. Zostało tam zaprezentowanych kilka ciekawych
							referatów m.in.
							<ul>
								<li>
									&apos;Zarys dziejów komunikacji miejskiej w Bydgoszczy&apos;.
								</li>
								<li>&apos;Tramwaj do Fordonu&apos;.</li>
								<li>
									&apos;Ocena zasadności przebiegu nowych tras tramwajowych w
									świetle obsługi komunikacyjnej mieszkańców Bydgoszczy&apos;.
								</li>
								<li>
									&apos;Wspomaganie finansowe projektów budownictwa drogowego ze
									środków finansowanych Unii Europejskiej&apos;.
								</li>
							</ul>
						</div>

						<div>
							Po południu wielu bydgoszczan odwiedziło wystawę filatelistyczną
							&apos;Komunikacja w Polsce i na świecie&apos;. Na siedmiu ekranach
							znalazły się ekspozycje:
							<ul>
								<li>
									&apos;Tramwajem po starej Europie&apos; (na pocztówkach z
									początku XX wieku),
								</li>
								<li>
									&apos;Jedyny taki tramwaj na świecie&apos; (Schwebebahn z 1901
									roku, tramwaj podwieszony w niemieckim Wuppertalu, z napędem
									na dachu, kursujący nad ulicami),
								</li>
								<li>
									&apos;Bydgoskie tramwaje w obiektywie&apos; (widzane
									artystycznie),
								</li>
								<li>
									&apos;Tramwaje w dokumentacji pocztowo-filatelistycznej&apos;,
								</li>
								<li>
									przegląd znaczków pocztowych przedstawiających początki
									komunikacji tramwajowej na świecie,
								</li>
								<li>
									&apos;Historia tramwaju&apos; na znakach Pawła Korcza,
									filatelisty z Poznania.
								</li>
							</ul>
						</div>

						<p>
							Wystawę przygotował p. Krystian Żurawski jedyny w rejonie pasjonat
							znaków związanych z komunikacją i transportem szynowym. Ta
							unikatowa ekspozycja była czynna w holu głównym Urzędu Pocztowego
							przy ul. Jagiellońskiej 6.
						</p>
						<p>
							Następnego dnia czyli w sobotę Miejskie Zakłady Komunikacyjne
							otworzyły natomiast bramy swoich zajezdni. Każdy mógł przyjechać
							do zajezdni tramwajowej przy ul. Toruńskiej zabytkowym wagonem
							&apos;Herbrand&apos; lub &apos;5N&apos; (wagony regularnie
							kursowały na trasie: pętla LPKiW - Zajezdnia tramwajowa).
							Pracownicy oprowadzali bydgoszczan po różnych zakamarkach hal.
							Pokazywali, gdzie są remontowane tramwaje, jak przebiega ich
							obsługa oraz jak się je myje i przygotowuje przed wyjazdem na
							trasę. W zajezdni można też było obejrzeć wystawę poświęconą
							komunikacji tramwajowej. Tego dnia na Toruńskiej zjawił się
							również drugi nowoczesny tramwaj typu &apos;122 N&apos;, który w
							nocy dostarczyła bydgoska PESA. Każdy kto zwiedził zajezdnię
							tramwajową mógł udać się w drogę powrotną tramwajem lub specjalną
							linią autobusową dojechać do zajezdni przy ul. Karola Szajnochy. W
							zajezdni autobusowej można było zapoznać się z kilkoma typami
							autobusów, które na co dzień wożą bydgoszczan. Dzieciom największą
							radość sprawiła możliwość zajęcia miejsca za kierownicą pojazdów.
							W zajezdni przy ul. Szajnochy, podobnie jak na Toruńskiej, każdy
							mógł zobaczyć hale obsługi autobusów oraz obejrzeć wystawę
							poświęconą historii komunikacji autobusowej.
						</p>
						<p>
							Na bydgoskiej imprezie pojawili się również miłośnicy z Warszawy i
							Poznania, którzy przyjechali zabytkowymi autokarami. Ich
							czerwonego &apos;ogórka&apos; czyli Jelcza 272 MEX oraz Jelcza 043
							z przyczepą PO-01 można było zobaczyć na placu przy ul. Grodzkiej.
							Swoje pojazdy Jelcz 043 i Jelcz M-11 zaprezentowała tam również
							sekcja Komunikacyjna Towarzystwa Miłośników Miasta Bydgoszczy oraz
							firma FR-bus - autobus Volvo B9M z 1983 r.
						</p>
						<p>
							Wielką atrakcją dla filatelistów była tramwajowa poczta, która
							funkcjonowała na trasie: Zajezdnia tramwajowa - pętla LPKiW -
							Zajezdnia tramwajowa. Zabytkowy wagon &apos;Herbrand&apos;
							przewoził przesyłki z całej Polski i po ostemplowaniu
							okolicznościowym stemplem (&apos;120 lat Komunikacji Miejskiej w
							Bydgoszczy&apos;) oraz datownikiem wracały do Urzędu Pocztowego
							przy ul. Jagiellońskiej 6. Oprócz tego Poczta Polska uruchomiła na
							Rybim Rynku specjalne stoisko, na którym można było nabyć
							okolicznościowe karty pocztowe i znaczki.
						</p>
						<p>
							Z okazji 120-lecia Komunikacji Miejskiej w Bydgoszczy, 31 maja
							2008 roku jeden skasowany w tramwaju bilet upoważniał do
							przejazduisMobile tramwajami przez cały dzień.
						</p>
						<p>Organizatorami imprezy byli:</p>
						<p>
							Towarzystwo Miłośników Miasta Bydgoszczy i Miejskie Zakłady
							Komunikacyjne Sp. z o.o. w Bydgoszczy oraz Urząd Miasta w
							Bydgoszczy, Zarząd Dróg Miejskich i Komunikacji Publicznej w
							Bydgoszczy, Centrum Poczty Oddział Regionalny w Bydgoszczy,
							Stowarzyszenie Inżynierów i Techników Komunikacji RP Oddział w
							Bydgoszczy, Bydgoski Klub Miłośników Komunikacji Miejskiej oraz
							firma FR-bus grupa Kowalski i Wspólnicy.
						</p>
						<p className="text-right">Stanisław Sitarek</p>
						<StyledImagesContainer>
							<StaticImage
								onClick={(e) => {
									// console.log();
									if (!isMobile) {
										setZoom(true);
										setImage(e.target.currentSrc);
									}
								}}
								className="bwfilter"
								src="../../static/images/120lat/01.jpg"
								alt=""
							/>
							<StaticImage
								onClick={(e) => {
									// console.log();
									if (!isMobile) {
										setZoom(true);
										setImage(e.target.currentSrc);
									}
								}}
								className="bwfilter"
								src="../../static/images/120lat/02.jpg"
								alt=""
							/>
							<StaticImage
								onClick={(e) => {
									// console.log();
									if (!isMobile) {
										setZoom(true);
										setImage(e.target.currentSrc);
									}
								}}
								className="bwfilter"
								src="../../static/images/120lat/03.jpg"
								alt=""
							/>
							<StaticImage
								onClick={(e) => {
									// console.log();
									if (!isMobile) {
										setZoom(true);
										setImage(e.target.currentSrc);
									}
								}}
								className="bwfilter"
								src="../../static/images/120lat/04.jpg"
								alt=""
							/>
							<StaticImage
								onClick={(e) => {
									// console.log();
									if (!isMobile) {
										setZoom(true);
										setImage(e.target.currentSrc);
									}
								}}
								className="bwfilter"
								src="../../static/images/120lat/05.jpg"
								alt=""
							/>
							<StaticImage
								onClick={(e) => {
									// console.log();
									if (!isMobile) {
										setZoom(true);
										setImage(e.target.currentSrc);
									}
								}}
								className="bwfilter"
								src="../../static/images/120lat/06.jpg"
								alt=""
							/>
							<StaticImage
								onClick={(e) => {
									// console.log();
									if (!isMobile) {
										setZoom(true);
										setImage(e.target.currentSrc);
									}
								}}
								className="bwfilter"
								src="../../static/images/120lat/07.jpg"
								alt=""
							/>
							<StaticImage
								onClick={(e) => {
									// console.log();
									if (!isMobile) {
										setZoom(true);
										setImage(e.target.currentSrc);
									}
								}}
								className="bwfilter"
								src="../../static/images/120lat/08.jpg"
								alt=""
							/>
							<StaticImage
								onClick={(e) => {
									// console.log();
									if (!isMobile) {
										setZoom(true);
										setImage(e.target.currentSrc);
									}
								}}
								className="bwfilter"
								src="../../static/images/120lat/09.jpg"
								alt=""
							/>
							<StaticImage
								onClick={(e) => {
									// console.log();
									if (!isMobile) {
										setZoom(true);
										setImage(e.target.currentSrc);
									}
								}}
								className="bwfilter"
								src="../../static/images/120lat/10.jpg"
								alt=""
							/>
							<StaticImage
								onClick={(e) => {
									// console.log();
									if (!isMobile) {
										setZoom(true);
										setImage(e.target.currentSrc);
									}
								}}
								className="bwfilter"
								src="../../static/images/120lat/11.jpg"
								alt=""
							/>
							<StaticImage
								onClick={(e) => {
									// console.log();
									if (!isMobile) {
										setZoom(true);
										setImage(e.target.currentSrc);
									}
								}}
								className="bwfilter"
								src="../../static/images/120lat/12.jpg"
								alt=""
							/>
							<StaticImage
								onClick={(e) => {
									// console.log();
									if (!isMobile) {
										setZoom(true);
										setImage(e.target.currentSrc);
									}
								}}
								className="bwfilter"
								src="../../static/images/120lat/13.jpg"
								alt=""
							/>
							<StaticImage
								onClick={(e) => {
									// console.log();
									if (!isMobile) {
										setZoom(true);
										setImage(e.target.currentSrc);
									}
								}}
								className="bwfilter"
								src="../../static/images/120lat/14.jpg"
								alt=""
							/>
							<StaticImage
								onClick={(e) => {
									// console.log();
									if (!isMobile) {
										setZoom(true);
										setImage(e.target.currentSrc);
									}
								}}
								className="bwfilter"
								src="../../static/images/120lat/15.jpg"
								alt=""
							/>
							<StaticImage
								onClick={(e) => {
									// console.log();
									if (!isMobile) {
										setZoom(true);
										setImage(e.target.currentSrc);
									}
								}}
								className="bwfilter"
								src="../../static/images/120lat/16.jpg"
								alt=""
							/>
							<StaticImage
								onClick={(e) => {
									// console.log();
									if (!isMobile) {
										setZoom(true);
										setImage(e.target.currentSrc);
									}
								}}
								className="bwfilter"
								src="../../static/images/120lat/17.jpg"
								alt=""
							/>
							<StaticImage
								onClick={(e) => {
									// console.log();
									if (!isMobile) {
										setZoom(true);
										setImage(e.target.currentSrc);
									}
								}}
								className="bwfilter"
								src="../../static/images/120lat/18.jpg"
								alt=""
							/>
							<StaticImage
								onClick={(e) => {
									// console.log();
									if (!isMobile) {
										setZoom(true);
										setImage(e.target.currentSrc);
									}
								}}
								className="bwfilter"
								src="../../static/images/120lat/19.jpg"
								alt=""
							/>
							<StaticImage
								onClick={(e) => {
									// console.log();
									if (!isMobile) {
										setZoom(true);
										setImage(e.target.currentSrc);
									}
								}}
								className="bwfilter"
								src="../../static/images/120lat/20.jpg"
								alt=""
							/>
							<StaticImage
								onClick={(e) => {
									// console.log();
									if (!isMobile) {
										setZoom(true);
										setImage(e.target.currentSrc);
									}
								}}
								className="bwfilter"
								src="../../static/images/120lat/21.jpg"
								alt=""
							/>
							<StaticImage
								onClick={(e) => {
									// console.log();
									if (!isMobile) {
										setZoom(true);
										setImage(e.target.currentSrc);
									}
								}}
								className="bwfilter"
								src="../../static/images/120lat/22.jpg"
								alt=""
							/>
						</StyledImagesContainer>
					</MessageDiv>
				</StyledArticle>
			</Layout>
			{zoom ? (
				<ImageZoom onClick={() => setZoom(false)}>
					<img src={image} alt="" />
				</ImageZoom>
			) : null}
		</>
	);
};

export default Lat120;
